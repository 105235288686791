<template>
<div>


 <v-data-table
    :headers="headersSync"
    :items="itemSync"
    sort-by="calories"
    class="elevation-1 mt-5"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Sincronizacion Usuarios</v-toolbar-title>

          <v-btn dark color="indigo" class="mx-5" @click="listarSync()"><v-icon>autorenew</v-icon></v-btn>

        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialogSync" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary"  dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <v-card>


             <v-toolbar color="red">
             <v-toolbar-title class="white--text">{{ formTitleSync }}</v-toolbar-title>
             <v-spacer></v-spacer>
             <v-icon dark @click="closeSync()">close</v-icon>
             </v-toolbar>


            <v-card-text>
              <v-container>
                <v-row>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItemSync.pais"
                      label="Pais"
                    ></v-text-field>
                  </v-col>


                   <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItemSync.servidor"
                      label="Servidor"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItemSync.user"
                      label="User"
                    ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItemSync.password"
                      label="Password"
                    ></v-text-field>
                  </v-col>


                      <v-col cols="12" sm="6" md="6">

                    <input type="radio" :value="1" v-model="editedItemSync.activo"> Activo
                    <input type="radio" :value="0" v-model="editedItemSync.activo"> Inactivo
 
                  </v-col>






                
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeSync()">
                Cancelar
              </v-btn>
              <v-btn color="green" dark @click="saveSync">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDeleteSync" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">deseas deshabilitar sincronizacion con usuario?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDeleteSync=false;closeSync() ">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirmSync">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon  small class="mr-2" @click="editItemSync(item)">
        edit
      </v-icon>
      <v-icon small @click="deleteItemSync(item)">
        delete
      </v-icon>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary"  @click="listarSync()">
        Reset
      </v-btn>
    </template>

     <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==1 ? "done" : "label_off" }}
        </v-icon>
  </template>

  </v-data-table>



</div>

  
</template>
    
<script>


import axios from 'axios';

import { mapMutations,mapState } from "vuex";

export default {

data: () => ({


    //datos de servidores a sincronizar


          headersSync:[
            { text: 'Pais', value: 'pais' },
            { text: 'Servidor', value: 'servidor' },
            { text: 'user', value: 'user' },
            { text: 'Password', value:'password'},
             { text: 'Activo', value:'activo'},
            { text: 'Acciones', value: 'actions', sortable: false },
          ],

      itemSync:[],

      dialogSync:false,

      editedItemSync:{
          _id:'',
          pais:'',
          servidor:'',
          user:'',
          password:'',
          activo:1
        }, 


        defaultItemSync:{
          _id:'',
          pais:'',
          servidor:'',
          user:'',
          password:'',
          activo:1

        },

      editedIndexSync: -1,

      dialogDeleteSync:false,

    }),

    computed: {

          ...mapState(['usuario', 'token']),

            formTitleSync(){

                if(this.editedIndexSync === -1){
                    return 'Nueva sincronizacion';
                }else{
                return 'Edicion sincronizar';

                }

            },


    },

     created () {
      this.listarSync()
    },

     methods: {

     ...mapMutations(['mostrarLoading','ocultarLoading']),

     



      listarSync () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'accediendo a datos'});
          let me=this;
          me.itemSync=[];

          axios.get('sicronizador', 
              config
          ).then(function(response){
              console.log(response);
              me.itemSync=response.data;
              me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });


       },



       editItemSync (item) {
        this.editedIndexSync = this.itemSync.indexOf(item)
        this.editedItemSync = Object.assign({}, item)
        this.dialogSync = true
      },


      deleteItemSync (item) {
        this.editedIndexSync = this.itemSync.indexOf(item)
        this.editedItemSync = Object.assign({}, item)
        this.dialogDeleteSync = true
      },

      deleteItemConfirmSync () {
       // this.ArrayServidores.splice(this.editedIndex, 1)
        //damos de baja al registro
           
           
                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItemSync._id;

                    axios.put(`sicronizador/${id}`,
                      {'activo':0},
                      
                     config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listarSync();
                         this.dialogDeleteSync=false;
                         this.ocultarLoading();
                         this.closeSync ();

                  }).catch(e=>{
                        console.log(e)
                  });


        this.closeDelete()
      },


      saveSync () {
        if (this.editedIndexSync > -1) {
        
            //edicion

                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItemSync._id;

                    axios.put(`sicronizador/${id}`,
                      {
                        'pais': this.editedItemSync.pais,
                        'servidor': this.editedItemSync.servidor,
                        'user':  this.editedItemSync.user,
                        'password':  this.editedItemSync.password,
                        'activo':  this.editedItemSync.activo,

                       },
                        config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listarSync();
                         this.dialogSync=false;
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

        } else {
        
          //nueva empresa

            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'actualizando'});

          axios.post('nuevo-sicronizador',
                    { 
                         'pais': this.editedItemSync.pais,
                        'servidor': this.editedItemSync.servidor,
                        'user':  this.editedItemSync.user,
                        'password':  this.editedItemSync.password,
                        'activo':1
                       },
              config
          ).then(response=>{
          
          
            console.log(response);
            this.listarSync();
            this.dialogSync=false;
            this.ocultarLoading();

          }).catch(e=>{
                console.log(e)
          });

        }
        this.close()
      },


      closeSync () {
        this.dialogSync = false
        this.$nextTick(() => {
          this.editedItemSync = Object.assign({}, this.defaultItemSync)
          this.editedIndexSync = -1
        })
      },


     
     
     }

}
</script>

<style>

</style>