<template>
<div>


      <v-data-table
    :headers="headersFiltros"
    :items="itemFiltros"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Eventos Filtrar</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog  v-model="dialogFiltro" max-width="500px">

          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitleFiltro }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>

                  <v-col cols="12" sm="12"   md="12">
                    <v-text-field
                      v-model="editedItemFiltro.nombre_filtro"
                      label="Nombre evento"
                    ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="12"   md="12">
                    <v-text-field
                      v-model="editedItemFiltro.descripcion"
                      label="Descripcion"
                    ></v-text-field>
                  </v-col>


                 <v-col cols="12" sm="6" md="6">

                    <input type="radio" :value="1" v-model="editedItemFiltro.activo"> Activo
                    <input type="radio" :value="0" v-model="editedItemFiltro.activo"> Inactivo
 
                  </v-col>




                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeFiltro()">
                Cancelar
              </v-btn>
              <v-btn color="green" dark @click="saveFiltro()">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDeleteFiltro" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">deseas deshabilitar evento?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteFiltro">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirmFiltro">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItemFiltro(item)">
        edit
      </v-icon>
      <v-icon small @click="deleteItemFiltro(item)">
        delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="listarFiltros()">
        Reset
      </v-btn>
    </template>

    <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==1 ? "done" : "label_off" }}
        </v-icon>
  </template>


   <template v-slot:[`item.creado`]="{ item }">
        <span>{{ FormatearFechaHora(item.creado)}}</span>
  </template>



  </v-data-table>


</div>
</template>
    
<script>

import axios from 'axios';

import { mapMutations,mapState } from "vuex";

export default {

     data: () => ({

           //datos de eventos a filtrar

            headersFiltros:[
              {text:'nombre_filtro', value:'nombre_filtro'},
              {text:'descripcion', value:'descripcion'},
              {text:'activo', value:'activo'},
              {text:'creado',value:'creado'},
              { text: 'Acciones', value: 'actions', sortable: false },
            ],
            itemFiltros:[],

            dialogFiltro:false,
            dialogDeleteFiltro:false,


            editedIndexFiltro:-1,

            editedItemFiltro:{
                    _id:'',
                    nombre_filtro:'',
                    descripcion:'',
                    activo:1
                }, 

                defaultItemFiltro:{
                        _id:'',
                        nombre_filtro:'',
                        descripcion:'',
                        activo:1
                    }, 



     }),

      computed: {

       ...mapState(['usuario', 'token']),

  

    
      formTitleFiltro(){

         if(this.editedIndexFiltro === -1){
            return 'Nuevo filtro';
        }else{
           return 'Edicion filtro';

        }

      }



   

    },

      created () {
      this.listarFiltros();
    },

    
    methods: {

     ...mapMutations(['mostrarLoading','ocultarLoading']),
       


      listarFiltros(){

          let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'accediendo a datos'});
          let me=this;
          me.itemFiltros=[];

          axios.get('filtros', 
              config
          ).then(function(response){
              console.log(response);
              me.itemFiltros=response.data;
              me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

      },


        editItemFiltro (item) {
        this.editedIndexFiltro = this.itemFiltros.indexOf(item)
        this.editedItemFiltro = Object.assign({}, item)
        this.dialogFiltro = true
      },

       closeFiltro () {
        this.dialogFiltro = false
        this.$nextTick(() => {
          this.editedItemFiltro = Object.assign({}, this.defaultItemFiltro)
          this.editedIndexFiltro = -1
        })
      },


      
      deleteItemFiltro (item) {
        this.editedIndexFiltro = this.itemFiltros.indexOf(item)
        this.editedItemFiltro = Object.assign({}, item)
        this.dialogDeleteFiltro = true
      },


        deleteItemConfirmFiltro () {
       // this.ArrayServidores.splice(this.editedIndex, 1)
        //damos de baja al registro
           
           
                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItemFiltro._id;

                    axios.put(`filtro/${id}`,
                      {'activo':0},
                      
                     config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listarFiltros();
                         this.dialogDeleteFiltro=false;
                         this.ocultarLoading();
                         this.closeFiltro ();

                  }).catch(e=>{
                        console.log(e)
                  });


        this.closeDeleteFiltro();
        },

       closeDeleteFiltro () {
        this.dialogDeleteFiltro = false
        this.$nextTick(() => {
          this.editedItemFiltro = Object.assign({}, this.defaultItemFiltro)
          this.editedIndexFiltro = -1
        })
      },


      saveFiltro () {
        if (this.editedIndexFiltro > -1) {
        
            //edicion

                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItemFiltro._id;

                    axios.put(`filtro/${id}`,
                      {
                        'nombre_filtro': this.editedItemFiltro.nombre_filtro,
                        'descripcion': this.editedItemFiltro.descripcion,
                        'activo':  this.editedItemFiltro.activo,

                       },
                        config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listarFiltros();
                         this.dialogFiltro=false;
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

        } else {
        
          //nueva empresa

            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'actualizando'});

          axios.post('nuevo-filtro',
                    { 
                         'nombre_filtro': this.editedItemFiltro.nombre_filtro,
                        'descripcion': this.editedItemFiltro.descripcion,
                       
                        'activo':1
                       },
              config
          ).then(response=>{
          
          
            console.log(response);
            this.listarFiltros();
            this.dialogFiltro=false;
            this.ocultarLoading();

          }).catch(e=>{
                console.log(e)
          });

        }
        this.closeFiltro()
      },


       FormatearFechaHora(FechaHoraInicial){
                       var pFechaHoraInicial= new Date(FechaHoraInicial).toISOString().substring(0,10);
                       var textoHora=FechaHoraInicial.split("T")[1];
                        textoHora= textoHora.substring(0,textoHora.length - 5);
                        return(pFechaHoraInicial+' '+textoHora);
      },



    },

}
</script>

<style>

</style>