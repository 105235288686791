<template>


<v-layout class="pa-0">

  <v-flex justify-center>


<div v-resize="onResize">

  <v-data-table
    :headers="headers"
    :items="ArrayVehiculos"
    sort-by="calories"
    :search="search"
      :height="tableHeight"
    class="elevation-1 mt-2 mx-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Vehiculos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        

        <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>

        <v-flex class="mt-4 mx-5" xs4 sm4 md4 lg3 xl3>
          <v-select v-model="id_estado_activo" dense 
          outlined :items="ArrayEstadosTodos" @change="listar()"  label="Estados"></v-select>
       </v-flex>


        <v-icon  color="gray" class="mx-5" @click="verInfo()">info</v-icon>


        <v-spacer></v-spacer>

    
           <v-btn color="primary" class="mx-2" @click="CrearUnidad()" dark><v-icon>add</v-icon></v-btn>
      

        <v-btn color="success" @click="listar();">
              <v-icon>restart_alt</v-icon>
              </v-btn>

        <v-dialog v-model="dialog" max-width="700px">
          <!--template v-slot:activator="{ on, attrs }"-->
           
            <!-- v-btn  color="primary" dark class="mx-1" v-bind="attrs" v-on="on">
              <v-icon>add</v-icon>
            </v-btn-->

          <!--/template-->
          <v-card>
          
            <v-toolbar color="red">
             <v-toolbar-title class="white--text">{{ formTitle }} : {{  editedItem.nombre}}  / {{  editedItem.placa}}</v-toolbar-title>
             <v-spacer></v-spacer>
             <v-icon dark @click="dialog=false">close</v-icon>
             </v-toolbar>



            <v-card-text>
              <v-container>
                <v-row>


              
                   <v-col cols="12"  sm="6" md="6">
                    <v-select
                    prepend-icon="settings"
                              v-model="editedItem.id_estado"
                              :items="ArrayEstados"
                              label="Estado"
                          ></v-select>
                  </v-col>


                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.fecha_gestion"
                      prepend-icon="today"
                      label="Fecha gestion"
                      :value="editedItem.fecha_gestion"
                       disabled
                    ></v-text-field>
                  </v-col>


                   <v-col cols="12" sm="4" md="4" class="mt-4">
                                                   
                      <v-combobox
                            class="d-inline" 
                            v-model="editedItem.contactado"
                            :items="items_contacto"
                            label="Contactado"
                          
                    ></v-combobox>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                      <v-select
                        v-model="editedItem.via_contacto"
                        :items="arrayViaContacto"
                        :menu-props="{ top: true, offsetY: true }"
                        prepend-icon="label"
                        label="Via contacto"
                      ></v-select>
                  </v-col>


               
                  <v-col cols="12" sm="6" md="4">
                   

                     <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="editedItem.vence_estado"
                            label="Fecha vence estado"
                            prepend-icon="date_range"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="editedItem.vence_estado"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                        </v-menu>

                  </v-col>


                   <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        name="observacion"
                        label="observacion"
                        v-model="editedItem.observacion"
                        rows="1"
                        prepend-icon="comment"
                        >
                      </v-textarea>
                  </v-col>



                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn  color="success" @click="save"> Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


         <v-dialog v-model="dialogNuevoVeh" max-width="700px">
      
          <v-card>
          
            <v-toolbar color="red">
             <v-toolbar-title class="white--text">Nueva unidad sin TX</v-toolbar-title>
             <v-spacer></v-spacer>
             <v-icon dark @click="dialogNuevoVeh=false">close</v-icon>
             </v-toolbar>



            <v-card-text>
              <v-container>
                <v-row>


                   <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.id_avl"
                        prepend-icon="tag"
                      label="Id Avl"
                      :value="editedItem.id_avl"
                    
                    ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.nombre"
                        prepend-icon="label_important"
                      label="Nombre"
                      :value="editedItem.nombre"
                      
                    ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.placa"
                        prepend-icon="closed_caption"
                      label="Placa"
                      :value="editedItem.placa"
                      
                    ></v-text-field>
                  </v-col>


                  


                   <v-col  cols="12" sm="6" md="7">
                  
                      <v-autocomplete
                      v-model="editedItem.id_veh_cliente"
                      :items="items_cliente"
                      label="Cliente"
                      item-value="value"
                      item-text="text"
                      persistent-hint
                      prepend-icon="face"
                      @change="actualizarContactos()"
                    >
                      </v-autocomplete>
                  </v-col>



              
                   <v-col cols="12"  sm="6" md="5">
                    <v-select
                    prepend-icon="settings"
                              v-model="editedItem.id_estado"
                              :items="ArrayEstados"
                              label="Estado"
                          ></v-select>
                  </v-col>


                


                   <v-col cols="12" sm="6" md="6" class="mt-4">
                                                   
                      <v-combobox
                            class="d-inline" 
                            v-model="editedItem.contactado"
                            :items="items_contacto"
                            label="Contactado"
                          
                    ></v-combobox>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                      <v-select
                        v-model="editedItem.via_contacto"
                        :items="arrayViaContacto"
                        :menu-props="{ top: true, offsetY: true }"
                        prepend-icon="label"
                        label="Via contacto"
                      ></v-select>
                  </v-col>


               
                  <v-col cols="12" sm="6" md="5">
                   

                     <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="editedItem.vence_estado"
                            label="Fecha vence estado"
                            prepend-icon="date_range"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="editedItem.vence_estado"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                        </v-menu>

                  </v-col>


                    <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.fecha_gestion"
                      prepend-icon="today"
                      label="Fecha gestion"
                      :value="editedItem.fecha_gestion"
                       disabled
                    ></v-text-field>
                  </v-col>


                   <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        name="observacion"
                        label="observacion"
                        v-model="editedItem.observacion"
                        rows="1"
                        prepend-icon="comment"
                        >
                      </v-textarea>
                  </v-col>



                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogNuevoVeh=false">Cancelar</v-btn>
              <v-btn  color="success" @click="saveNuevoItem()"> Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>



        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">deseas borrar registro?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>




      </v-toolbar>
    </template>


    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)" color="green">edit</v-icon>
      <!--v-icon small @click="deleteItem(item)">delete</v-icon-->

    </template>


    <template v-slot:[`item.fecha_gps`]="{ item }">
      <span>{{  getDate(item.fecha_gps) }}</span>
      <v-icon  v-if="ValidariconoSiTX(item.fecha_gps)==true" color="success">event_available</v-icon>
    </template>


    <template v-slot:[`item.ubicacion`]="{ item }">
      <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
    </template>




       <template v-slot:[`item.id_estado`]="{ item }">

         <v-chip v-if="item.id_estado.nombre_estado=='Desconocido'" @click="editItem(item)" small color="red" outlined>
           <span>{{ item.id_estado.nombre_estado }}</span>
         </v-chip>


         <v-chip v-else-if="item.id_estado.nombre_estado=='Sin confirmar'" @click="editItem(item)" small color="red" outlined>
           <span>{{ item.id_estado.nombre_estado }}</span>
         </v-chip>


         <v-chip v-else small color="blue" outlined @click="editItem(item)">
           <span>{{ item.id_estado.nombre_estado }}</span>
         </v-chip>


    </template>


     <template v-slot:[`item.vence_estado`]="{ item }">
           <span v-if="item.vence_estado!=null">{{ new Date(item.vence_estado).toISOString().substr(0, 10) }}</span>
          <v-icon  v-if="item.vence_estado!=null" color="red">{{ evaluarVencimineto(item.vence_estado)}}</v-icon>
                                     
   
    </template>


     <template v-slot:[`item.fecha_gestion`]="{ item }">
           <span v-if="item.fecha_gestion!=null">{{ getDate(item.fecha_gestion) }}</span>
    </template>


    

    

    


  



    <template v-slot:no-data>
      <v-btn  color="primary" @click="listar()">
        Reset
      </v-btn>
    </template>
  </v-data-table>


   <v-flex xs12 sm12 md12 lg12 class="pb-2">
    <span class="d-block">
    <v-icon color="red">error</v-icon>
    Estados vencidos de vehiculos</span>
    </v-flex>

  

</div>

  </v-flex>
</v-layout>
</template>

<script>


import axios from 'axios';
import { mapMutations,mapState } from "vuex";

  export default {
    data: () => ({
      search:'',
      dialog: false,
      dialogDelete: false,
      ArrayVehiculos:[],
      headers: [
        { text: 'nombre', value: 'nombre' },
        { text: 'Placa', value: 'placa' },
        { text: 'Flota', value: 'flota' },

        { text: 'Fecha GPS', value: 'fecha_gps' },
        { text: 'Ubicacion', value: 'ubicacion' },

        { text: 'Estado', value: 'id_estado' },
        { text:'Fecha gestion', value:'fecha_gestion'},

        { text:'Contactado', value:'contactado'},
        { text:'Vence estado', value:'vence_estado'},
        { text:'Observacion', value:'observacion'},
        { text:'Cliente', value:'id_veh_cliente.nombre_cliente'},
        { text: 'Actualizado', value: 'actualizado' }


      ],
     
      editedIndex: -1,
      editedItem: {

        id_avl:'',
        nombre:'',
        placa:'',
        flota:'',
        icono:'',
        fecha_gps:'',
        fecha_tx:'',
        evento:'',
        velocidad:'',
        direccion:'',
        latitud:'',
        longitud:'',
        id_estado:'',
        fecha_gestion:new Date(),
        contactado:'',
        vence_estado:'',
        observacion:'',
        id_veh_cliente:'',
        comentario:''

      },
      defaultItem: {
        
        id_avl:'',
        nombre:'',
        placa:'',
        flota:'',
        icono:'',
        fecha_gps:'',
        fecha_tx:'',
        evento:'',
        velocidad:'',
        direccion:'',
        latitud:'',
        longitud:'',
        id_estado:'',
        fecha_gestion:new Date(),
        contactado:'',
        via_contacto:'', //nuevo campo 
        vence_estado:'',
        observacion:'',
        id_veh_cliente:'',
        comentario:''
      },

      ArrayEstados:[],
      ArrayEstadosTodos:[{text:'Todos', value:0}],
      menu1:false,
      date: new Date().toISOString().substr(0, 10),

      id_estado_activo:'',

      tableHeight: 0,

      arrayViaContacto: ['Llamada', 'Whatsapp', 'Correo', 'Telegram','Visita'],
      items_contacto:[], //selecionar a quien contactamos


      //creacion de nueva unidad

      dialogNuevoVeh:false,

       ArrayClientes:[],
      items_cliente:[],


    }),

    computed: {

       ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo vehiculo' : 'Edicion'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {

      this.id_estado_activo=0; //seleccionamos por defecto todo
     
      this.listarEstados();
      this.listar();
      this.listarClientes();
    },

    methods: {

   ...mapMutations(['mostrarLoading','ocultarLoading']),

      listar () {

        let config={headers:{token:this.token}};
        this.mostrarLoading({titulo:'Accediendo a datos'});

        this.ArrayVehiculos=[];

         let me=this;
          axios.get('vehiculos',
         config
          ).then(function(response){
             console.log(response);


             if((me.id_estado_activo==0)){  //si vemos todos los estados

                   if(me.usuario.rol=='ADMIN'){
                     me.ArrayVehiculos=response.data;
                   }else {

                     let arreglo=response.data;

                        arreglo.map(function(x){
                            if(x.id_veh_cliente.id_ejecutivo_cli==me.usuario._id){
                                me.ArrayVehiculos.push(x);
                            }
                        });


                      

                   } 


             }else{  //si hay un estado seleccionado


                let arreglo=response.data;

                arreglo.map(function(x){
                    if((x.id_estado._id==me.id_estado_activo)&&((me.usuario.rol=='ADMIN')||(x.id_veh_cliente.id_ejecutivo_cli==me.usuario._id))){
                         me.ArrayVehiculos.push(x);
                    }
                });


             }
          
           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

      
       },

        listarEstados(){

          let config={headers:{token:this.token}};
           this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;
          axios.get('estados',
          config
          ).then(function(response){
             console.log(response);
           
             let arreglo=response.data;

             arreglo.map(function(x){
               me.ArrayEstados.push({text:x.nombre_estado, value:x._id});
               me.ArrayEstadosTodos.push({text:x.nombre_estado, value:x._id});
             });

            me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });


       },

       listarClientes(){


         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

          me.items_cliente=[];
          me.ArrayClientes=[];

          axios.get('clientes-all', config
                    ).then(function(response){
                      console.log(response);
                      me.ArrayClientes=response.data;

                       me.ArrayClientes.map(function(x){
                            me.items_cliente.push({'value':x._id, 'text':x.nombre_cliente});
                       });

                       me.actualizarContactos();

                       me.ocultarLoading();
                    }).catch(function(error){
                      console.log(error);
                    });




      },

      
      actualizarContactos(){

       this.items_contacto=[];
       this.items_direcciones=[];
       this.itemsContact=[]; //almacena para enviar todos los contactos al backend
       let me=this;

        me.ArrayClientes.map(function(x){

            if(me.editedItem.id_veh_cliente==x._id){

              let contactos=x.contactos;
              me.itemsContact=x.contactos;

             // console.log(contactos);

              contactos.map(function(z){
                  me.items_contacto.push(z.nombre_contacto+'-'+z.telefonos);
              });

            
            }

        });

       

      },


      editItem (item) {
        this.editedIndex = this.ArrayVehiculos.indexOf(item)
        this.editedItem = Object.assign({}, item)

        let me=this;

        this.editedItem.fecha_gestion=me.getDate(new Date());

        if(this.editedItem.vence_estado!=null){
         
         this.editedItem.vence_estado= new Date(this.editedItem.vence_estado).toISOString().substr(0, 10)
        }

        
        this.items_contacto=[];

        let contactos_cli=item.id_veh_cliente.contactos;


         contactos_cli.map(function(z){
                  me.items_contacto.push(z.nombre_contacto+'-'+z.telefonos);
          });


        this.editedItem.id_estado=item.id_estado._id;
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayVehiculos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {

        //deshabilitado el boton de borrar unidades
        this.ArrayVehiculos.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          //Object.assign(this.ArrayVehiculos[this.editedIndex], this.editedItem)  
          //edicion de vehiculos

            if((this.editedItem.id_estado) && (this.editedItem.id_estado!='') 
                  && (this.editedItem.contactado) && (this.editedItem.contactado!='')
                  && (this.editedItem.observacion) &&(this.editedItem.observacion!='')
                  &&(this.editedItem.via_contacto!='')){


                 //actualizar datos de item
                      let config={headers:{token:this.token}};
                     this.mostrarLoading({titulo:'Accediendo a datos'});
             
                    let id=this.editedItem._id;
                    let me=this;

                    axios.put(`vehiculos/${id}`,
                      { 
                       '_id':this.editedItem._id,
                       'id_estado':this.editedItem.id_estado,
                       'fecha_gestion':new Date(),
                       'contactado':this.editedItem.contactado,
                       'vence_estado':this.editedItem.vence_estado,
                       'via_contacto':this.editedItem.via_contacto,
                       'observacion':this.editedItem.observacion
                     
                       },
                       config

                        ).then(response=>{
                      
                        console.log(response);
                        this.guardarHistorial();
                         me.ocultarLoading();
                      
                      //  this.close();

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });

        }else{
          alert('faltan campos');
        }






        } else {
          //this.ArrayVehiculos.push(this.editedItem)
          //nuevo registro de vehiculo


        
        
        }
       
      },

      getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    },

      getDateVence(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day;
    return formatedDay + "-" + formatedMonth + "-" + year;
    },


    openGoogleMap(item){
        const urlSuffix = item.latitud +','+ item.longitud;
        window.open(
          "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
          "_blank"
        );
    },


    guardarHistorial(){

             
               //nuevo ejecutivo

                   let config={headers:{token:this.token}};
                     this.mostrarLoading({titulo:'Accediendo a datos'});
                     let me=this;

                axios.post('nuevo-historico',
                   {
                      id_avl:this.editedItem.id_avl,
                      nombre:this.editedItem.nombre,
                      placa:this.editedItem.placa,
                      flota:this.editedItem.flota,
                      icono:this.editedItem.icono,
                      fecha_gps:this.editedItem.fecha_gps,
                      fecha_tx:this.editedItem.fecha_tx,
                      evento:this.editedItem.evento,
                      velocidad:this.editedItem.velocidad,
                      direccion:this.editedItem.direccion,
                      latitud:this.editedItem.latitud,
                      longitud:this.editedItem.longitud,

                      id_estado:this.editedItem.id_estado,
                      
                      fecha_gestion:new Date(),
                      contactado:this.editedItem.contactado,
                      vence_estado:this.editedItem.vence_estado,
                      observacion:this.editedItem.observacion,

                      id_veh_cliente:this.editedItem.id_veh_cliente,


                      activo:1,
                      creado:new Date(),
                      actualizado:me.usuario.nombre_ejecutivo+', a las: '+me.getDate(new Date()),
                      comentario:'usuario:'+this.usuario.nombre_ejecutivo

                   },
                       config
                ).then(response=>{
                
                 
                  console.log(response);
                  me.GuardarGestion();

                 

                }).catch(e=>{
                      console.log(e)
                    
                });

    },

       //por cada actualizacion de vehicu se generara una nueva gestion de tipo ATC 
      GuardarGestion(){

            let me=this;

           //registrar nueva gestion a cliente
                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'Accediendo a datos'});


                    let placas_gestion=this.editedItem.placa;


                    console.log('cliente');
                    console.log(this.editedItem.id_veh_cliente);

                    axios.post('nueva-gestion',
                      { 
                      
                       'tipo_gestion':this.editedItem.via_contacto,  //llamada, whatsapp, correo, telegram, visita
                       'departamento':'Atc',
                       'id_gestion_cliente':this.editedItem.id_veh_cliente,
                       'descripcion':this.editedItem.observacion+"("+placas_gestion+")",
                       'fecha':new Date(),
                       'id_gestion_ejecutivo':me.usuario._id,
                       'actualizado': me.usuario.nombre_ejecutivo+', a las: '+me.getDate(new Date())
                     
                       },
                       config

                        ).then(response=>{
                      
                        console.log(response);
                        this.close();

                         me.listar();
                      

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });


      },



    onResize() {

              if (window.innerWidth < 769){

                //mobile
                this.tableHeight = window.innerHeight - 20;
              }else{
                //pantalla grande
                this.tableHeight = window.innerHeight - 245;
              }
      },

      verInfo(){
        alert("Observaciones:clic en estado para actualizar observacion, \n  vehiculos se sincronizaran a las 7 y 12 medio dia.\n Unidades sin transmitir de mas de 24 hrs, \n las gestiones deberan ser ingresadas de inmediato, \n  los comentarios agregados seran informados a los clientes. \n Vehiculos no seran dados de baja de forma automatica.");
     },

    
      evaluarVencimineto(fechaVence){

            let icon='';
            let fecha_hoy=new Date();
            fecha_hoy.setHours(0,0,0,0);


            let arra_fecha=fechaVence.split("T");
                          fechaVence=arra_fecha[0];
         
            let fecha_recibida=new Date(fechaVence +" 00:00:00");
             fecha_recibida.setHours(0,0,0,0);
          
            console.log(fecha_hoy+', con:'+fecha_recibida);

            console.log(fecha_hoy.getTime()+', con:'+ fecha_recibida.getTime());
            
              if(fecha_hoy.getTime() === fecha_recibida.getTime()){
                     icon='error';

                 }else if(fecha_hoy < fecha_recibida){
                          icon='';
                        
                 }else if(fecha_hoy > fecha_recibida){
                         icon='error';   
                 }   

    
        return(icon);


      },


     CrearUnidad(){

          
          this.dialogNuevoVeh=true;
      },

      saveNuevoItem(){

             //nuevo ejecutivo



             if((this.editedItem.id_avl!='')&&(this.editedItem.nombre!='')&&(this.editedItem.placa!='')
             &&(this.editedItem.id_estado!='')&&(this.editedItem.contactado!='')
             &&(this.editedItem.observacion!='')&&(this.editedItem.via_contacto!='')){

                   let config={headers:{token:this.token}};
                     this.mostrarLoading({titulo:'Accediendo a datos'});
                     let me=this;

                axios.post('nuevo-vehiculo',
                   {
                      id_avl:this.editedItem.id_avl,
                      nombre:this.editedItem.nombre,
                      placa:this.editedItem.placa,
                      flota:this.editedItem.flota,
                      icono:'https://xcd.neuralcreations.com/cdn/rqxdavl/gmap/icons/truck-white/SE.png',
                      fecha_gps:new Date(),
                      fecha_tx:new Date(),
                      evento:'Normal apagado',
                      velocidad:0,
                      direccion:'',
                      latitud:0,
                      longitud:0,

                      id_estado:this.editedItem.id_estado,
                      
                      fecha_gestion:new Date(),
                      contactado:this.editedItem.contactado,
                      vence_estado:this.editedItem.vence_estado,
                      observacion:this.editedItem.observacion,

                      id_veh_cliente:this.editedItem.id_veh_cliente,
                      activo:1,
                      creado:new Date(),
                      actualizado:me.usuario.nombre_ejecutivo+', a las: '+me.getDate(new Date()),
                      comentario:'usuario:'+this.usuario.nombre_ejecutivo

                   },
                       config
                ).then(response=>{
                

                  console.log(response);
                   me.dialogNuevoVeh = false;

                   me.GuardarGestion();
                  

                   me.ocultarLoading();

                }).catch(e=>{
                      console.log(e)
                    
                });

      }else{

          alert('faltan campos');
      }

      },


      ValidariconoSiTX(fecha_gps){

        let estado_tx=false;

            let fecha_hoy=new Date(this.CalcularFechaHoy()+' 01:00:00');
            let fecha_recibida=new Date(fecha_gps);


              if((fecha_hoy.getDate()==fecha_recibida.getDate())
                 &&(fecha_hoy.getMonth()==fecha_recibida.getMonth())
                 &&(fecha_hoy.getFullYear()==fecha_recibida.getFullYear())) {
                      // console.log('fechas iguales:'+fecha_hoy+', con:'+fecha_recibida);
                    estado_tx=true;
                
                 }else{
                     estado_tx= false;   
                       /* console.log('fechas diferentes:'+fecha_hoy+', con:'+fecha_recibida);

                        console.log('dif:'+ fecha_hoy.getDate()+', con:'+fecha_recibida.getDate());
                        console.log('dif:'+ fecha_hoy.getMonth()+', con:'+fecha_recibida.getMonth());
                        console.log('dif:'+ fecha_hoy.getFullYear()+', con:'+fecha_recibida.getFullYear());

                        */
                 
                 }


                 return(estado_tx);

      },

     CalcularFechaHoy(){
	
        var fecha=new Date();
        var mes=fecha.getMonth()+1;
        if(mes<10)mes='0'+mes;
        var dia=fecha.getDate();
        if(dia<10)dia='0'+dia;
        var textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

        return(textofecha);
      },
       

    },
  }
</script>