<template>

<div v-resize="onResize">
  <v-data-table
    :headers="headers"
    :items="arrayBloqueo"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Vehiculos con Bloqueo de eventos</v-toolbar-title>
        
        <v-divider class="mx-4" inset vertical></v-divider>

          <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>

        <v-spacer></v-spacer>


         <v-btn color="primary" @click="listar()">
              <v-icon dark >sync</v-icon>
            </v-btn>


        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">seguro deseas deshabilitar el bloqueo?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green" dark @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue" dark @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>
  </v-data-table>

</div>
</template>


<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";

  export default {
    data: () => ({

      search:'',
      dialogDelete: false,
      headers: [
        { text: 'ID Avl', value: 'id_avl'},
        { text: 'Nombre', value: 'nombre'},
        { text: 'Placa', value: 'placa'},
        { text: 'Flota', value: 'flota'},
        { text: 'Ingresado', value: 'observacion'},
        { text: 'Accion', value: 'actions', sortable: false },
      ],
      arrayBloqueo: [],
      editedIndex: -1,
      editedItem: {
        id_avl:'',
        nombre:'',
        placa:'',
        flota:'',
        activo:1,
        creado:new Date(),
        observacion:''
      },
      defaultItem: {
        id_avl:'',
        nombre:'',
        placa:'',
        flota:'',
        activo:1,
        creado:new Date(),
        observacion:''
      },

       tableHeight: 0,

    }),

    computed: {

        
        ...mapState(['usuario', 'token']),
    
    },

    watch: {
     
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
        this.$root.$refs.M.detenerTimer();
      this.listar();
    },

    methods: {

           ...mapMutations(['mostrarLoading','ocultarLoading']),

        listar(){

            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'accediendo a datos'});
            let me=this;
            me.arrayBloqueo=[];

            axios.get('bloqueos', config
                ).then(function(response){
                    console.log(response);
                me.arrayBloqueo=response.data;

                me.ocultarLoading();
            }).catch(function(error){
                console.log(error);
            });

        },


      deleteItem (item) {
        this.editedIndex = this.arrayBloqueo.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },


      deleteItemConfirm () {
                   console.log('vamos a deshabilitar bloqueo')
        
                    let config={headers:{token:this.token}};
                    let me=this;
             
                    let id=this.editedItem._id;
                     this.mostrarLoading({titulo:'Accediendo a datos'});

                    axios.put(`bloqueo/${id}`,
                      { 
                           'observacion': me.usuario.nombre_ejecutivo+', a las: '+me.getDate(new Date()),
                            'activo':0
                       }, config

                        ).then(response=>{
                      
                        console.log(response);
                         me.ocultarLoading();
                     
                        this.listar();
                        this.closeDelete();

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });


      },


      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },


        onResize() {

                if (window.innerWidth < 769){

                  //mobile
                  this.tableHeight = window.innerHeight - 20;
                }else{
                  //pantalla grande
                  this.tableHeight = window.innerHeight - 245;
                }
       },

         getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    },



     
    },
  }
</script>