<template>


<div v-resize="onResize">

  <v-data-table
    :headers="headers"
    :items="ArrayEventos"
    :search="search"
    :height="tableHeight"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Historico Eventos</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>


        <v-flex  xs5 sm5 md4 lg4>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>

        <v-btn color="indigo" dark class="mx-5" @click="dialogInicio=true"><v-icon>today</v-icon></v-btn>


          <vue-excel-xlsx v-show="data_excel.length >0"
              :data="data_excel"
              :columns="column_excel"
              :filename="'Historial'"
              :sheetname="'hoja1'">
              <v-btn color="info">
                  <v-icon dark>get_app</v-icon>
              </v-btn>
            
              </vue-excel-xlsx>
        <v-spacer></v-spacer>

    


        <v-dialog   v-model="dialog"  max-width="500px">

          <v-card>


            <v-toolbar color="red">
            <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialog=false">close</v-icon>
          </v-toolbar>


            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-textarea
                      prepend-icon="description"
                      v-model="editedItem.observacion"
                      label="Observacion"
                      rows="2"
                    ></v-textarea>
                  </v-col>

                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="green" dark @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-toolbar>
    </template>


    <template v-slot:no-data>
      <v-btn color="primary"    @click="initialize">
        Reset
      </v-btn>
    </template>


 <template v-slot:[`item.fecha`]="{ item }">
      <span>{{  getDate(item.fecha) }}</span>
    </template>


     <template v-slot:[`item.observacion`]="{ item }">
        <v-icon small class="mr-2"   @click="editItem(item)">edit</v-icon>
      <span>{{  item.observacion }} - {{ item.edicion}}</span>
    </template>

     <template v-slot:[`item.ubicacion`]="{ item }">
      <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
    </template>




      <template v-slot:[`item.evento`]="{ item }">

         <v-chip :color="getColor(item)" dark @click="editItem(item)" small >
           <span>{{ item.evento }}</span>
         </v-chip>


        


    </template>

  </v-data-table>


  
<v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="red" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Historial por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>



           <v-flex>
      
          

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="success" dark @click="listarHistorial()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
</v-dialog>



</div>


</template>


<script>



import axios from 'axios';
import { mapMutations,mapState } from "vuex";


  export default {
    data: () => ({

        menu: false,
       dialogInicio:false,
       menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),

       search:'',
      ArrayEventos:[],
      dialog: false,
      dialogDelete: false,
      headers: [

       // { text: 'ID', value: 'id_avl'},
        { text: 'Nombre', value: 'nombre'},
        { text: 'Placa', value: 'placa'},
        { text: 'Fleet', value: 'flota'},

        { text: 'Fecha', value: 'fecha'},
        { text: 'Evento', value: 'evento'},


        { text: 'Direccion', value: 'direccion' },
        { text: 'Ubicacion', value: 'ubicacion' },

        { text: 'Observacion', value: 'observacion' }
      
      ],
    
      editedIndex: -1,
      editedItem: {
            id_avl:'',
            nombre: '',
            placa: '',
            flota: '',
            fecha: '',
            evento: '',
            direccion:'',
            observacion:'',
            editado:'',


      },
      defaultItem: {
            id_avl:'',
            nombre: '',
            placa: '',
            flota: '',
            fecha: '',
            evento: '',
            direccion:'',
            observacion:'',
            editado:'',
      },

        tableHeight: 0,


          data_excel:[],
          column_excel:[ 
                    {label: "id_avl",  field: "id_avl"},
                    {label: "nombre",  field: "nombre"},
                    {label: "placa", field: "placa"},
                    {label: "flota", field: "flota"},

                    {label: "fecha", field: "fecha"},

                    {label: "evento",  field: "evento"},

                    {label: "latitud",  field: "latitud"},
                    {label: "longitud",  field: "longitud"},

                    {label: "observacion", field: "observacion"},
                    {label: "editado", field: "editado"}
                    ],

    }),

    computed: {

        ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo' : 'Editar Observacion'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
        this.$root.$refs.M.detenerTimer();
        
     this.dialogInicio=true;
    },

    methods: {

       ...mapMutations(['mostrarLoading','ocultarLoading']),
       

      initialize () {
        this.ArrayEventos = [];

        this.listarHistorial();
      },

      
      listarHistorial(){

        this.data_excel=[];

        // let fecha_inicio=new Date().toISOString().substr(0, 10);
         //let fecha_fin=new Date().toISOString().substr(0, 10);

          let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'accediendo a datos'});
          let me=this;
          me.ArrayEventos=[];

          axios.post('eventos-all', {'desde':this.fecha_inicio,
                                'hasta':this.fecha_fin },
              config
          ).then(function(response){
              console.log(response);
              me.ArrayEventos=response.data;


                me.ArrayEventos.map(function(x){
                   me.data_excel.push({ 
                              'id_avl':x.id_avl,
                              'nombre':x.nombre,
                              'placa':x.placa,
                              'flota':x.flota,
                              'fecha':me.getDate(x.fecha),
                              'evento':x.evento,
                              'latitud':x.latitud,
                              'longitud':x.longitud,
                              'observacion':x.observacion,
                              'editado':x.editado
                              });


                });

              me.ocultarLoading();
              me.dialogInicio=false;
          }).catch(function(error){
            console.log(error);
          });

      },






      editItem (item) {
        this.editedIndex = this.ArrayEventos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayEventos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.ArrayEventos.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
         // Object.assign(this.ArrayEventos[this.editedIndex], this.editedItem)

          //edicion de estados


           //actualizar datos de item
                  let config={headers:{token:this.token}};
                    let me=this;
             
                    let id=this.editedItem._id;
                     this.mostrarLoading({titulo:'Accediendo a datos'});

                    axios.put(`eventos/${id}`,
                      { 
                           'observacion':this.editedItem.observacion,
                            'editado':me.usuario.nombre_ejecutivo+', a las: '+me.getDate(new Date())
              
                       },
                      config

                        ).then(response=>{
                      
                        console.log(response);
                         me.ocultarLoading();
                     
                        this.listarEventos();
                        this.close();

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });


        }
        this.close()
      },


        onResize() {

                if (window.innerWidth < 769){

                  //mobile
                  this.tableHeight = window.innerHeight - 20;
                }else{
                  //pantalla grande
                  this.tableHeight = window.innerHeight - 220;
                }
       },


      getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    },


    openGoogleMap(item){
        const urlSuffix = item.latitud +','+ item.longitud;
        window.open(
          "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
          "_blank"
        );
    },


      getColor (item) {

        let StringBuscar='Boton';
        let posicionBuscar = item.evento.indexOf(StringBuscar);



        if ((item.evento=='Boton de panico')||(item.evento=='Botón de pánico')||(posicionBuscar !== -1)){


                if(item.editado !=''){
                        return 'pink';
                }else{
                      return 'red';
                }
                  
          } else{


              if(item.editado !=''){
                  return 'gray';
              }else{
                    return 'green';
              }



          
          }
      },





    },
  }
</script>