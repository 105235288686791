<template>


<div v-resize="onResize">

  <v-data-table
    :headers="headers"
    :items="ArrayEventos"
    :search="search"
    :height="tableHeight"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Monitoreo</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>


        <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>



        <v-chip class="mx-5" color="red" dark>Nuevos {{ NuevosEventos }} </v-chip>


        <v-switch class="ml-5 mt-5"
        v-model="switchOrden"
        :label="LabelSwitch"
      ></v-switch>

     
         

        <v-spacer></v-spacer>

        <v-btn color="primary" @click="listarEventos()">
              <v-icon dark >sync</v-icon>
            </v-btn>


        <v-dialog   v-model="dialog"  max-width="700px">

          <v-card>


            <v-toolbar color="red">
            <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialog=false">close</v-icon>
          </v-toolbar>


            <v-card-text>
              <v-container>

                <v-row>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Vehiculo"
                    v-model="editedItem.nombre"
                    disabled
                 ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Placa"
                    v-model="editedItem.placa"
                    disabled
                 ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Evento"
                     v-model="editedItem.evento"
                     disabled
                 ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Fecha"
                    v-model="editedItem.fecha"
                    disabled
                 ></v-text-field>
                </v-col>





                  <v-col cols="12" sm="6" md="12">
                    <v-textarea
                      background-color="grey lighten-2"
                      prepend-icon="description"
                      v-model="editedItem.observacion"
                      label="Observacion"
                    placeholder="aqui ingrese observacion"
                      rows="2"
                    ></v-textarea>
                  </v-col>






                  <v-col cols="12" v-show="itemSelected.length >1">


                         <v-checkbox
                           v-model="checkboxAll"
                            label="Seleccionar todos"
                            @change="CheckboxTodos()"
                         ></v-checkbox>



                            <v-data-table
                              :headers="headersSelected"
                              :items="itemSelected"
                            >

                                    
                                    <template v-slot:[`item.fecha`]="{ item }">
                                      <span>{{ getDate(item.fecha) }}</span>
                                    </template>


                                    <template v-slot:[`item.estado_check`]="{ item }">
                                      <v-checkbox
                                        v-model="item.estado_check"
                                        
                                      ></v-checkbox>
                                </template>

                              
                            </v-data-table>

                  </v-col>




                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="grey" class="mr-7 white--text" small @click="ConfirmarBloqueo()">
                <v-icon dark>no_transfer</v-icon> Bloquear
                </v-btn>
              
              <v-spacer></v-spacer>
              
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="green" dark @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-toolbar>
    </template>


    <template v-slot:no-data>
      <v-btn color="primary"    @click="initialize">
        Reset
      </v-btn>
    </template>


 <template v-slot:[`item.fecha`]="{ item }">
      <span>{{  getDate(item.fecha) }}</span>
    </template>


     <template v-slot:[`item.observacion`]="{ item }">
        <v-icon small class="mr-2"   @click="editItem(item)">edit</v-icon>
      <span>{{  item.observacion }} </span>
      <span class="caption">- {{ item.editado}}</span>
    </template>

     <template v-slot:[`item.ubicacion`]="{ item }">
      <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
    </template>




     <template v-slot:[`item.whatsapp`]="{ item }">
      <v-btn @click="ContactarWhataspp(item)" color="success" icon><v-icon dark>whatsapp</v-icon></v-btn>
    </template>


   <template v-slot:[`item.contacto`]="{ item }">
      <v-btn @click="VerContactos(item)" color="primary" icon><v-icon dark>contact_page</v-icon></v-btn>
    </template>
    




      <template v-slot:[`item.evento`]="{ item }">

         <v-chip :color="getColor(item)" dark @click="editItem(item)" small >
           <span>{{ item.evento }}</span>
         </v-chip>

    </template>




  </v-data-table>


    <v-dialog v-model="dialogContactos" max-width="780px">
            <v-card>
            <v-toolbar color="red">
            <v-toolbar-title class="white--text"> Contactos de: {{ nombre_cliente_texto }} </v-toolbar-title>
            
            <v-btn color="fondo1" class="ml-8" @click="dialogEdicionContacto=true"><v-icon>add</v-icon></v-btn>
           
            <v-spacer></v-spacer>
            <v-icon dark @click="dialogContactos=false">close</v-icon>
          </v-toolbar>

          <v-card-text>

             


           
            <v-data-table
            :headers="headersContact"
            :items="itemsContact"
            sort-by="calories"
            class="elevation-1 mt-5"
          >

            <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItemContacto(item)">edit</v-icon>
            <v-icon small @click="deleteItemContacto(item)"> delete</v-icon>
          </template>
        
          </v-data-table>


           <v-row class="mt-1">

                  <v-col cols="12" sm="3" md="5">
                    <v-text-field
                      v-model="editedItemCliente.numero_whatsapp"
                      prepend-icon="whatsapp"
                      label="Numero whatsapp "
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3" md="4">
                    <v-text-field
                      v-model="editedItemCliente.responsable"
                      prepend-icon="face"
                      label="nombre responsable"
                    ></v-text-field>
                  </v-col>

                    <v-col cols="12" sm="4" md="3">
                      <v-btn color="success" class="mt-2" @click="GuardarNumeroWhatsapp()">Guardar</v-btn>  
                    </v-col>

              </v-row>


          </v-card-text>

            </v-card>
    </v-dialog>

     <v-dialog v-model="dialogEdicionContacto" max-width="500px" persistent>

            <v-card>
            <v-toolbar color="red">
            <v-toolbar-title class="white--text"> Edicion Contactos </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialogEdicionContacto=false">close</v-icon>
          </v-toolbar>

          <v-card-text>

            <v-row>

              <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedContacto.nombre_contacto"
                      prepend-icon="face"
                      label="Nombre contacto"
                    ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedContacto.telefonos"
                      prepend-icon="phone"
                      label="Telefonos"
                    ></v-text-field>
                  </v-col>

                   <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedContacto.correos"
                      prepend-icon="email"
                      label="Correos"
                    ></v-text-field>
                  </v-col>


                   <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedContacto.observacion"
                      prepend-icon="comment"
                      label="observacion"
                    ></v-text-field>
                  </v-col>

            </v-row>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="GuardarContactos()">Guardar</v-btn>

            <v-btn text color="primary" @click="dialogEdicionContacto=false">cancelar</v-btn>
          </v-card-actions>
            </v-card>


    </v-dialog>





</div>


</template>


<script>



import axios from 'axios';
import { mapMutations,mapState } from "vuex";


  export default {
     name: 'M',
    data: () => ({
       search:'',
      ArrayEventos:[],
      dialog: false,
      dialogDelete: false,
      headers: [

       // { text: 'ID', value: 'id_avl'},
        { text: 'Nombre', value: 'nombre'},
        { text: 'Placa', value: 'placa'},
        { text: 'Fleet', value: 'flota'},
        { text: 'Fecha', value: 'fecha'},
        { text: 'Evento', value: 'evento'},
        { text: 'Direccion', value: 'direccion'},
        { text: 'Whatsapp', value: 'whatsapp'},
        { text: 'Contacto', value: 'contacto'},
        { text: 'Ubicacion', value: 'ubicacion'},
        { text: 'Observacion', value: 'observacion'}
      
      ],
    
      editedIndex: -1,
      editedItem: {
            id_avl:'',
            nombre: '',
            placa: '',
            flota: '',
            fecha: '',
            evento: '',
            direccion:'',
            observacion:'',
            editado:'',


      },
      defaultItem: {
            id_avl:'',
            nombre: '',
            placa: '',
            flota: '',
            fecha: '',
            evento: '',
            direccion:'',
            observacion:'',
            editado:'',
      },

        tableHeight: 0,
        NuevosEventos:0,


        
          headersSelected:[
            {text: 'Seleccionar', value:'estado_check'},
            { text: 'Vehiculo', value: 'vehiculo' },
            { text: 'Placa', value: 'placa' },
            { text: 'Evento', value: 'evento' },
            { text: 'Fecha', value: 'fecha' },
           
            ],
          itemSelected:[],
          singleSelect:false,

           timer: null,
           checkboxAll:false,
           switchOrden:false,
           LabelSwitch:'Fecha asc',


           respuesta_eventos:[],
           dialogContactos:false,
            headersContact:[
            { text: 'Acciones', value: 'actions', sortable: false },
            { text: 'Nombre contacto', value: 'nombre_contacto' },
            { text: 'Telefonos', value: 'telefonos' },  
            { text: 'Correos', value: 'correos' }, 
            { text: 'Observacion', value: 'observacion' }  
            ],
          itemsContact:[],
          nombre_cliente_texto:'',


          dialogEdicionContacto:false,

          editedContacto:{
            nombre_contacto:'',
            telefonos:'',
            correos:'',
            observacion:'',
          },

          tipo_edicion:'nuevo',
          indice_contacto:0,

          id_cliente_visualizar:'',
          editedItemCliente:{
               numero_whatsapp:'',
               responsable:'',
              },


    }),

    computed: {

        ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo' : 'Editar Observacion'
      },

    esOperador(){
        if(this.$store.state.usuario){
              return this.$store.state.usuario && this.$store.state.usuario.rol=='OPERATOR';
        }else{
          return false;
        }
         
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },


       switchOrden(val){
          if(val==true){
             this.LabelSwitch='Observ. asc';
             this.ActulizarTabla();
          }else{
               this.LabelSwitch='Fecha asc';
                this.ActulizarTabla();
          }
      },

      dialogEdicionContacto(val){
        if(val==false){
           this.tipo_edicion='nuevo';
           this.editedContacto={};
           this.editedItemCliente={};
           this.indice_contacto=0;
        }
       },
       dialogContactos(val){

          if(val==false){
           this.tipo_edicion='nuevo';
           this.editedContacto={};
           this.editedItemCliente={};
           this.id_cliente_visualizar='';
           this.indice_contacto=0;
        }

       }

    },

    created () {
      this.initialize();

        this.$root.$refs.M = this;  //metodo estara en Monitoreo desacivar sincronizacion 
    },

    methods: {

       ...mapMutations(['mostrarLoading','ocultarLoading']),
       

      initialize () {
        this.ArrayEventos = [];

        

        this.listarEventos();

        this.ejecutarTimer();
      },

      
      listarEventos(){

        this.NuevosEventos=0;

       //  let fecha_inicio=new Date().toISOString().substr(0, 10);
         //let fecha_fin=new Date().toISOString().substr(0, 10);

         let fecha_hoy=this.CalcularFechaHoy();

          let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'accediendo a datos'});
          let me=this;
          me.ArrayEventos=[];

          axios.post('eventos', {'desde':fecha_hoy,
                                'hasta':fecha_hoy
                                 },
              config
          ).then(function(response){
              console.log(response);

              me.respuesta_eventos=response.data;
              let segunda_validacion=response.data;
             // me.ArrayEventos=response.data;


             if(me.switchOrden==true){  //orden por observacion vacia

                      //insertamos los utimos sin datos
                      segunda_validacion.map(function(x){
                        if(x.editado==""){
                              me.ArrayEventos.push(x);
                        }  
                    });

                      //insertamos primero eventos por fecha
                    me.respuesta_eventos.map(function(x){
                        if(x.editado==""){
                              me.NuevosEventos++;
                        }else{
                          me.ArrayEventos.push(x);
                        }  
                    });


             }else{  //orden por fecha nada mas

                      me.ArrayEventos=response.data;

                      me.respuesta_eventos.map(function(x){
                        if(x.editado==""){
                              me.NuevosEventos++;
                        }
                    });

             }

      


          




              me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

      },






      editItem (item) {
       this.itemSelected=[];
        this.editedIndex = this.ArrayEventos.indexOf(item)
        this.editedItem = Object.assign({}, item);
        if(this.editedItem.fecha){this.editedItem.fecha=this.getDate(this.editedItem.fecha);}

        let me=this;

           console.log('id actualizar'+item._id);


          this.ArrayEventos.map(function(x){
              if(((x.id_avl==item.id_avl)&&(x.editado==""))||(x._id==item._id)){



                let estado_check=false;

                if(x._id==item._id){
                    estado_check=true;
                  console.log('es igual el: '+x._id+', con el :'+item._id);
                }


              let ingresar= { '_id':x._id, 'vehiculo': x.nombre,'placa': x.placa,
                              'evento': x.evento, 'fecha': x.fecha,
                               'estado_check':estado_check };


                me.itemSelected.push(ingresar);
              }
          });

       
        

        this.dialog = true;
        this.detenerTimer();
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayEventos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true;
      },

      deleteItemConfirm () {
        this.ArrayEventos.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {

        if(this.editedItem.observacion!=''){

            this.detenerTimer();

            if (this.editedIndex > -1) {
            // Object.assign(this.ArrayEventos[this.editedIndex], this.editedItem)

              //edicion de estados


              let ArregloEnviar=[];


              if(this.itemSelected.length >1){

                let me=this;


                this.itemSelected.map(function(x){


                  if(x.estado_check==true){
                      ArregloEnviar.push(x._id);
                  }


                     });//fin de for de tabla

                  
                    let config={headers:{token:me.token}};
                        
                
                       
                        me.mostrarLoading({titulo:'Accediendo a datos'});


                            axios.put(`eventos-all`,
                              { 
                                'arreglo':ArregloEnviar,
                                'observacion': me.editedItem.observacion,
                                'editado': me.usuario.nombre_ejecutivo+', a las: '+me.getDate(new Date())
                              },
                              config

                                ).then(response=>{
                              
                                console.log(response);
                                
                          }).catch(e=>{
                                console.log(e)
                          });



             


                          me.listarEventos();
                            me.close();

                            me.ejecutarTimer();
                    
                    
                   





              }else{


              //actualizar datos de item
                      let config={headers:{token:this.token}};
                        let me=this;
                
                        let id=this.editedItem._id;
                        this.mostrarLoading({titulo:'Accediendo a datos'});

                        axios.put(`eventos/${id}`,
                          { 
                              'observacion':this.editedItem.observacion,
                                'editado':me.usuario.nombre_ejecutivo+', a las: '+me.getDate(new Date())
                  
                          },
                          config

                            ).then(response=>{
                          
                            console.log(response);
                            me.ocultarLoading();
                        
                            this.listarEventos();
                            this.close();

                              me.ejecutarTimer();

                      }).catch(e=>{
                            console.log(e)
                            this.mensaje=e
                      });

            }


            }
            this.close()
      }else{
          alert('debes ingresar observacion');
      }


      },


        onResize() {

                if (window.innerWidth < 769){

                  //mobile
                  this.tableHeight = window.innerHeight - 20;
                }else{
                  //pantalla grande
                  this.tableHeight = window.innerHeight - 220;
                }
       },


      getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    },


    openGoogleMap(item){
        const urlSuffix = item.latitud +','+ item.longitud;
        window.open(
          "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
          "_blank"
        );
    },


      getColor (item) {

        let StringBuscar='Boton';
        let posicionBuscar = item.evento.indexOf(StringBuscar);



        if ((item.evento=='Boton de panico')||(item.evento=='Botón de pánico')||(posicionBuscar !== -1)){


                if(item.editado !=''){
                        return 'pink';
                }else{
                      return 'red';
                }
                  
          } else{


              if(item.editado !=''){
                  return 'gray';
              }else{
                    return 'green';
              }



          
          }
      },


      ejecutarTimer(){



            if(this.esOperador==true){

            
              this.timer = setInterval(() => {
                  this.listarEventos();
                }, 60000);

              }

      },

      detenerTimer(){
         clearInterval(this.timer);
      },



      CheckboxTodos(){

        if(this.checkboxAll==true){


              this.itemSelected.map(function(x){

                x.estado_check=true;

              });


        }else{

             let me=this;

            this.itemSelected.map(function(x){
                if(x._id!=me.editedItem._id){
                    x.estado_check=false;
                }

              });

        }


      },


  CalcularFechaHoy(){
	
	var fecha=new Date();
	var mes=fecha.getMonth()+1;
	if(mes<10)mes='0'+mes;
	var dia=fecha.getDate();
	if(dia<10)dia='0'+dia;
	var textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

	return(textofecha);
},

ConfirmarBloqueo(){
  let mensaje= confirm('seguro deseas bloquear alertas de este vehiculo, las alertas ya no seran agregadas en ventana de monitoreo?');

    if(mensaje==true){
        console.log('vamos a bloquearlo entonces');

              //nuevo ejecutivo
                let config={headers:{token:this.token}};
                let me=this;
                this.mostrarLoading({titulo:'Accediendo a datos'});
                axios.post('nuevo-bloqueo',
                          { 
                            'id_avl':this.editedItem.id_avl,
                            'nombre':this.editedItem.nombre,
                            'placa':this.editedItem.placa,
                            'flota':this.editedItem.flota,
                            'observacion':me.usuario.nombre_ejecutivo+', a las: '+me.getDate(new Date()),
                            'activo':1
                            },
                       config
                ).then(response=>{
                
                  this.close();
                  console.log(response);
                  me.ocultarLoading();
                  this.listarEventos();

                }).catch(e=>{
                      console.log(e)
                    
                });
    }
},

ActulizarTabla(){


              this.ArrayEventos=[];
              this.NuevosEventos=0;

             let me=this;


             let segunda_validacion=me.respuesta_eventos;


               if(me.switchOrden==true){  //orden por observacion vacia

                      //insertamos los utimos sin datos
                      segunda_validacion.map(function(x){
                        if(x.editado==""){
                              me.ArrayEventos.push(x);
                        }  
                    });

                      //insertamos primero eventos por fecha
                    me.respuesta_eventos.map(function(x){
                        if(x.editado==""){
                              me.NuevosEventos++;
                        }else{
                          me.ArrayEventos.push(x);
                        }  
                    });


             }else{  //orden por fecha nada mas

                      me.ArrayEventos=me.respuesta_eventos;

                      me.respuesta_eventos.map(function(x){
                        if(x.editado==""){
                              me.NuevosEventos++;
                        }
                    });

             }




},


    ContactarWhataspp(item){

    //si posee saldo enviar datos
      if((item.id_veh_cliente)&&(item.id_veh_cliente.numero_whatsapp)){


        if(item.id_veh_cliente.numero_whatsapp!=""){


          let number = item.id_veh_cliente.numero_whatsapp;
          let MyMessage="sr(a)."+item.id_veh_cliente.responsable;
          MyMessage=MyMessage+", Disatel le informa de Alerta por:"+item.evento;
          MyMessage=MyMessage+" del vehiculo:"+item.nombre+"  con placa:"+item.placa;
          MyMessage=MyMessage+", detectado a las:"+new Date(item.fecha).toLocaleDateString();
          MyMessage=MyMessage+' Link ubicacion: '+' https://www.google.com/maps?q='+item.latitud+','+item.longitud;
          MyMessage=MyMessage+". Gracias por utilizar nuestros servicios";

        
          let message = MyMessage.split(' ').join('%20');

          let URLReditreccionar='https://api.whatsapp.com/send?phone=' + number + '&text=%20' + message;

          console.log(URLReditreccionar);
          window.open(URLReditreccionar, "_blank");

        }else{
        alert('No posee whatsapp al cual notificar');
        }


        

      }else{
        alert('No se posee contacto');
      }



    },//fin de contactar

    VerContactos(item){

        if((item.id_veh_cliente)&&(item.id_veh_cliente.contactos)){

            this.itemsContact=item.id_veh_cliente.contactos;
            this.nombre_cliente_texto=item.id_veh_cliente.nombre_cliente;

            this.editedItemCliente.numero_whatsapp=item.id_veh_cliente.numero_whatsapp;
            this.editedItemCliente.responsable=item.id_veh_cliente.responsable;
            this.dialogContactos=true;

            this.id_cliente_visualizar=item.id_veh_cliente._id;

        }else{
          alert('evento no fue asociado a cliente');
          console.log(item)
        }

    },

    GuardarContactos(){

              let me=this;

            if(this.tipo_edicion=='nuevo'){
                  this.itemsContact.push(this.editedContacto);
              }else if(this.tipo_edicion=='actualizar'){  //actualizar
                    this.itemsContact[me.indice_contacto]=this.editedContacto;
              }


                //actualizar datos de item
                        let config={headers:{token:this.token}};
                      
                
                    
                        this.mostrarLoading({titulo:'Accediendo a datos'});

                        axios.put(`clientes/${this.id_cliente_visualizar}`,
                          { 
                              'contactos':this.itemsContact,
                          },
                          config

                            ).then(response=>{
                          
                            console.log(response);
                            me.ocultarLoading();
                            this.editedContacto={};
                        
                            this.listarEventos();
                            this.dialogEdicionContacto=false;

                      }).catch(e=>{
                            console.log(e)
                            this.mensaje=e
                      });


        
            


    },

    editItemContacto(item){

          this.tipo_edicion='actualizar';
          this.editedContacto=item;
          this.dialogEdicionContacto=true;

          this.indice_contacto=this.itemsContact.indexOf(item);
          console.log('indice a contacto:'+this.indice_contacto);

      },

    deleteItemContacto(item){

        this.tipo_edicion='borrar';

        this.dialogDeleteContacto=true;
        this.indice_contacto=this.itemsContact.indexOf(item);
        

        //console.log(item);

      },

    deleteContactConfirm(){

       

        this.itemsContact.splice(this.indice_contacto, 1);
         this.GuardarContactos();
        this.dialogDeleteContacto=false;

      },

    GuardarNumeroWhatsapp(){

                    let config={headers:{token:this.token}};
                    let me=this;
             
                  
                     this.mostrarLoading({titulo:'Accediendo a datos'});

                    axios.put(`clientes/${this.id_cliente_visualizar}`,
                      { 
                            'numero_whatsapp':this.editedItemCliente.numero_whatsapp,
                            'responsable':this.editedItemCliente.responsable
                       },
                      config

                        ).then(response=>{
                          
                        console.log(response);
                         me.ocultarLoading();
                     
                        this.listarEventos();
                        this.dialogContactos=false;

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });

    }






    },
  }
</script>