<template>
<div>

<v-card>
   <v-toolbar flat>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Parametros Aplicacion</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon><v-icon>magnify</v-icon></v-btn>

      <v-btn icon> <v-icon>dots-vertical</v-icon></v-btn>

      <template v-slot:extension>
        <v-tabs v-model="tabs" fixed-tabs>

          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#mobile-tabs-5-1" class="primary--text">
            <v-icon>filter_alt</v-icon>
          </v-tab>

          <v-tab href="#mobile-tabs-5-2" class="primary--text">
            <v-icon>sync</v-icon>
          </v-tab>

          <v-tab href="#mobile-tabs-5-3" class="primary--text">
            <v-icon>email</v-icon>
          </v-tab>

         

        </v-tabs>
      </template>
    </v-toolbar>



  <v-tabs-items v-model="tabs">

    <v-tab-item :value="'mobile-tabs-5-1'">
        <v-card flat>
          <v-card-text>
             <Filtros />
          </v-card-text>
        </v-card>
      </v-tab-item>


     <v-tab-item :value="'mobile-tabs-5-2'">
        <v-card flat>
          <v-card-text>
             <Sincronizadores />
          </v-card-text>
        </v-card>
      </v-tab-item>


    <v-tab-item :value="'mobile-tabs-5-3'">
        <v-card flat>
          <v-card-text>
            <ServidoresSmtp />
          </v-card-text>
        </v-card>
      </v-tab-item>



  </v-tabs-items>

 










 </v-card>
</div>
</template>
<script>

import Sincronizadores from './Sincronizadores.vue';
import ServidoresSmtp from './ServidoresSmtp.vue';
import Filtros from './Monitoreo/Filtros.vue';

  export default {
      components:{
           ServidoresSmtp, Sincronizadores,
           Filtros
         },

    data: () => ({

       tabs: null,

    }),




  }
</script>